import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from '../components/layout'
import Breadcrumb from "../components/Global/Breadcrumb"
import "./../css/Staffs.css";

import fbWhite from "./../images/facebook-logo-blue.svg";
import instaWhite from "./../images/instagram-logo-blue.svg";
import twitter from "./../images/twitter-brands.svg";
import wordpress from "./../images/wordpress-brands.svg";
import youtube from "./../images/youtube-brands.svg";


function StaffTemplate(props) {
    let data = props.data;
    console.log(props)

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.service.serviceCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}/${data.service.serviceCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbSlug}/`
        })
        bCrumb.push({
            text: data.service.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpStaff.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>

                    <section>
                        <Breadcrumb h1={data.allWpStaff.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>


                    <section className="container-cs flex flex-wrap m-auto justify-center md:my-10 px-4 py-16 md:py-20">
                        <div className="flex flex-wrap justify-around items-start">
                            <div className="w-full md:w-1/4 flex flex-wrap pb-4 md:pb-0">
                                {(data.allWpStaff.nodes[0].featuredImage) && (
                                    <img
                                        src={data.allWpStaff.nodes[0].featuredImage.node.sourceUrl}
                                        alt={data.allWpStaff.nodes[0].featuredImage.node.altText}
                                        className="m-auto sc-m-img" />

                                )}

                                <div className="w-full flex mt-4">
                                    {data.allWpStaff.nodes[0].staffAcf.staffSocial.facebook && (
                                        <a href={data.allWpStaff.nodes[0].staffAcf.staffSocial.facebook} className="st-social" target="_blank" rel="noopener" ><img src={fbWhite} alt="facebook-icon" style={{ width: "15px" }} /></a>
                                    )}
                                    {data.allWpStaff.nodes[0].staffAcf.staffSocial.facebook && (
                                        <a href={data.allWpStaff.nodes[0].staffAcf.staffSocial.instagram} className="st-social ml-2" target="_blank" rel="noopener"><img src={instaWhite} alt="instagram-icon" style={{ width: "15px" }} /></a>
                                    )}

                                    {data.allWpStaff.nodes[0].staffAcf.staffSocial.twitter && (
                                        <a href={data.allWpStaff.nodes[0].staffAcf.staffSocial.twitter} className="st-social ml-2" target="_blank" rel="noopener"><img src={twitter} alt="twitter-icon" style={{ width: "15px" }} /></a>
                                    )}

                                    {data.allWpStaff.nodes[0].staffAcf.staffSocial.youtube && (
                                        <a href={data.allWpStaff.nodes[0].staffAcf.staffSocial.youtube} className="st-social ml-2" target="_blank" rel="noopener"><img src={youtube} alt="youtube-icon" style={{ width: "15px" }} /></a>
                                    )}

                                    {data.allWpStaff.nodes[0].staffAcf.staffSocial.website && (
                                        <a href={data.allWpStaff.nodes[0].staffAcf.staffSocial.website} className="st-social ml-2" target="_blank" rel="noopener"><img src={wordpress} alt="wordpress-icon" style={{ width: "15px" }} /></a>
                                    )}

                                </div>
                            </div>
                            <div className="w-full md:w-3/4 pl-0 md:pl-4">
                                <div>
                                    <h1 className="st-h1">{data.allWpStaff.nodes[0].title}</h1>
                                </div>
                                <div className="st-content-editor" dangerouslySetInnerHTML={{ __html: data.allWpStaff.nodes[0].content }} />
                            </div>
                        </div>
                    </section>

                </div>
            </Layout>
        </div>

    )
}

export default StaffTemplate

export const pageQuery = graphql`query GET_STAFF($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpStaff(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
        
       }
       content
       slug
       uri
       excerpt
       title
       featuredImage{
         node{
           altText
           sourceUrl
         }
       }
       staffAcf{
         staffSocial{
           facebook
           instagram
           twitter
           website
           youtube
         }
       }
    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`